import { useContext, useState } from 'react';
import { paymentGeneral } from '../api/paymentGeneral';
import { EApiStatus } from '~/shared/api/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { ModalContext } from '~/context/modal/modalContext';
import { useTranslation } from 'react-i18next';

type TGeneralPay = [() => void, boolean, boolean];

export const useGeneralPayment = (session: string, amount: number): TGeneralPay => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { ymHit } = useYandexHit();
    const { show: showModal } = useContext(ModalContext);
    const { t } = useTranslation('components');

    const generalPayment = async () => {
        setLoading(true);
        ymHit(EYMActions.PAYMENT_GENERAL);

        try {           
            const query = await paymentGeneral(session, amount);

            if (query.error === EApiStatus.OK) {
                window.location.href = query.data.PaymentURL;
                return;
            }

            showModal({
                title: t('payments.hooks.generalPay.modalTitle'),
                text: t('payments.hooks.generalPay.errorValue'),
            });

            setError(true);
            setLoading(false);
        } catch (error) {
            showModal({
                title: t('payments.hooks.generalPay.modalTitle'),
                text: t('payments.hooks.generalPay.errorValue'),
            });

            setLoading(false);
            setError(true);
        }
    };

    return [
        generalPayment,
        loading,
        error,
    ];
};
